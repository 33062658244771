import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MessageService } from 'primeng/api';

import { registerLocaleData } from '@angular/common';
import localEsMx from '@angular/common/locales/es-MX';
registerLocaleData(localEsMx, 'es-MX');

import { routes } from './app.routes';
import { routes as home } from './pages/home/home.routes';
import { routes as login } from './pages/login/login.routes';
import { routes as antecendetes } from './pages/antecendetes/validate.routes';
import { routes as inhabilitaciones } from './pages/inhabilitaciones/validate.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    MessageService,

    { provide: LOCALE_ID, useValue: "es-MX" },
    provideAnimations(),
    provideHttpClient(),

    provideRouter(login, withComponentInputBinding()),
    provideRouter(antecendetes, withComponentInputBinding()),
    provideRouter(inhabilitaciones, withComponentInputBinding()),

    provideRouter(routes, withComponentInputBinding()),
  ]
};
